import React from 'react'
import TopBanner from '../../components/topBanner/topBanner'
import imgBanner from "../../images/banner/banner-tin-tuc.jpg"
import Title_center from '../../components/title/TitleCenter'

import {newsMenu} from '../../localData/newsMenu'
import { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

function DetailNews() {
    const { slug } = useParams();
    const [data, setData] = useState();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);
    console.log(slug)
    useEffect(() => {
        for (var item of newsMenu) {
            if (item.path === slug) {
                setData(item)
            }
        }
    }, [])
    console.log(data)
    const formattedHTML = { __html: data ? data.description : "" };
  return (
    <div className='DetailNews' style={{paddingBottom:"3rem"}}>
         <TopBanner
            titleCenter="Tin Tức"
            imgBanner={imgBanner}
            pageName={data?.categoryID == 1 ?"Tin Hoạt Động " : "Tin Sự Kiện "}
            text={data ? data.title : ""}
        />
        <div className='layout_container'>
        <Title_center
            text= {data ? data.title : ""}
         />
        <div className="DetailNews-content">
        <p  dangerouslySetInnerHTML={formattedHTML}></p>
        </div> 
        </div>
    </div>
  )
}

export default DetailNews