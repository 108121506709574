const imgnew1 = require("../images/news/active/02507112022.jpg");
const imgnew2 = require("../images/news/active/02607112022.jpg");
const imgnew3 = require("../images/news/active/02707112022.jpg");
const imgnew4 = require("../images/news/active/Anh_1.jpg");
const imgnew5 = require("../images/news/active/Anh_2.jpg");
const imgnew6 = require("../images/news/active/Anh_3.jpg");
const imgnew7 = require("../images/news/active/Anh_4.jpg");
const imgnew8 = require("../images/news/active/Anh_5.jpg");
const imgnew9 = require("../images/news/active/Anh_6.jpg");
const imgnew10 = require("../images/news/active/Anh_7.jpg");
const imgnew11 = require("../images/news/active/2-1668175726-34-width740height493.jpg");
const imgnew12 = require("../images/news/active/3-1668175734-882-width740height493.jpg");
const imgnew13 = require("../images/news/active/1667464047-51ca4feafdddbe414a3934d3de5236d0-width850height444.jpeg");
const imgnew14 = require("../images/news/active/1667464047-764ecb3d71ab1188beb7171669b94d69-width850height756.jpeg");
const imgnew15 = require("../images/news/active/1667527429-0d72880832f70189791c6efebfac8639-width850height513.jpeg");
const imgnew16 = require("../images/news/active/1667527429-324e5b1107e590d1037e5d2031b24085-width850height499.jpeg");
const imgnew17 = require("../images/news/active/dashboard-copy--1--1667576628-391-width1430height909.jpg");
const imgnew18 = require("../images/news/active/zoho_gibu22-edit-1667576628-865-width1280height747.jpg");
const imgnew19 = require("../images/news/active/2_Cnet.jpg");
const imgnew20 = require("../images/news/active/Twitter_firing_email_2.jpeg");
const imgnew21 = require("../images/news/active/Twitter_firing_email.jpeg");
const imgnew22 = require("../images/news/active/TTTTTTTT.jpg");
const imgnew23 = require("../images/news/active/TTTTTTTTTTTTTTTT.jpg");
const imgnew24 = require("../images/news/active/TAIWAN_SEMICONDUCTOR_iStock_Pony.png");
const imgnew25 = require("../images/news/events/1667288778-aded72a960d33d45016a8d51bf0557a0-width850height445.jpg");
const imgnew26 = require("../images/news/events/image2-1667358253-205-width2000height2000.jpg");
const imgnew27 = require("../images/news/events/image1-1667358253-168-width2000height2000.jpg");
const imgnew28 = require("../images/news/events/image3-1667358253-553-width2000height2000.jpg");
const imgnew29 = require("../images/news/events/image4-1667358253-810-width1280height853.jpg");
const imgnew30 = require("../images/news/events/1667402850-a44b9c0efb1ced4470623d85ee080f5b-width700height393.jpg");
const imgnew31 = require("../images/news/events/thaiholdings_tower.jpeg");
const imgnew32 = require("../images/news/events/thi_gia_cp_thd.jpg");
const imgnew33 = require("../images/news/events/-6758-1663337799.jpg");
const imgnew34 = require("../images/news/events/-7620-1663337799.jpg");
const imgnew35 = require("../images/news/events/1667117255-216fe46ac86f49235d554097958c707e-width645height428.jpg");
const imgnew36 = require("../images/news/events/1666318039-lan-16663142184791198203492-width684height484.jpeg");
const imgnew37 = require("../images/news/events/1666318039-tuan-1666314703521211139817-width640height421.jpeg");
const imgnew38 = require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411863_2.jpg");
const imgnew39 = require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411871.jpg");
const imgnew40 = require("../images/news/events/deepseak.png");
const imgnew41 = require("../images/news/events/Race_Deepseek.png");
const imgnew42 = require("../images/news/events/deepseak2.png");
const imgnew43 = require("../images/news/events/1x_1.png");
const imgnew44 = require("../images/news/events/PM_Pham_Minh_Chinh_Choi_Joo_Ho_Samsung_Vietnam_VGP_1.png");
const imgnew45 = require("../images/news/events/PM_Pham_Minh_Chinh_Choi_Joo_Ho_Samsung_Vietnam_VGP_2.png");
const imgnew46 = require("../images/news/events/PM_Pham_Minh_Chinh_Choi_Joo_Ho_Samsung_Vietnam_VGP_3.png");
const imgnew47 = require("../images/news/events/tiktok.png");
const imgnew48 = require("../images/news/events/elonmust.png");
const imgnew49 = require("../images/news/events/chatgpt_openai_png.png");
const imgnew50 = require("../images/news/events/05115022025.png");

export const newsMenu = [
    {
        id: 1,
        title: `Khi nào ChatGPT 5 ra mắt?`,
        path: "khi-nao-chatgpt-5-ra-mat-post",
        title_content: `CEO OpenAI cho biết dự kiến các mô hình GPT-4.5 và GPT-5 sẽ xuất hiện trong "vài tuần hoặc vài tháng tới".`,
        img: imgnew49,
        description: `    <p>
        CEO OpenAI cho biết dự kiến các mô hình GPT-4.5 và GPT-5 sẽ xuất hiện trong "vài tuần hoặc vài tháng tới".
        <br/><br/>
        <img src=${imgnew49} alt"">
        <br/><br/>
        CEO Sam Altman vừa chia sẻ lộ trình phát triển các mô hình AI GPT-4.5 và GPT-5 trong một bài đăng trên X ngày 12/2. Trong bài viết này, Altman thừa nhận rằng hệ sinh thái sản phẩm của OpenAI đang trở nên phức tạp và công ty muốn làm tốt hơn trong việc đơn giản hóa các lựa chọn cho người dùng.
        <br/><br/>
        "Chúng tôi ghét trình chọn mô hình (model picker) cũng như các bạn và muốn quay trở lại với trí tuệ nhân tạo thống nhất”, vị CEO viết.
        <br/><br/>
        Theo kế hoạch, GPT-4.5, hay còn được gọi nội bộ với tên mã Orion, sẽ là mô hình AI cuối cùng của OpenAI không sử dụng phương pháp "chain-of-thought" (chuỗi suy luận). Trước đó, các trang tin từng đưa tin về một số chi tiết liên quan đến Orion vào năm ngoái.
        <br/><br/>
        Sau GPT-4.5, OpenAI đặt mục tiêu hợp nhất các dòng mô hình dòng o và dòng GPT bằng cách tạo ra một hệ thống có thể sử dụng tất cả công cụ của công ty, biết khi nào cần tư duy lâu dài và khi nào không, đồng thời có khả năng xử lý tốt một loạt các nhiệm vụ đa dạng, theo The Verge.
        <br/><br/>
        Với ChatGPT và API của OpenAI, công ty dự định ra mắt GPT-5 như một hệ thống tích hợp nhiều công nghệ, bao gồm cả o3. Điều này đồng nghĩa với việc OpenAI sẽ không còn phát hành o3 dưới dạng một mô hình độc lập nữa. Mô hình o3 lần đầu tiên được OpenAI hé lộ vào tháng 12/2024 và phiên bản o3-mini đã được ra mắt vào tháng 1 năm nay.
        <br/><br/>
        Về người dùng, CEO Sam Altman tiết lộ rằng khi GPT-5 được phát hành, người dùng miễn phí của ChatGPT sẽ có quyền truy cập trò chuyện không giới hạn ở mức "trí tuệ tiêu chuẩn". Người dùng gói Plus sẽ được sử dụng GPT-5 ở mức "trí tuệ cao hơn". Còn người đăng ký gói Pro sẽ có quyền truy cập vào mức "trí tuệ thậm chí còn cao hơn nữa".
        <br/><br/>
        Altman không đưa ra mốc thời gian cụ thể cho việc ra mắt GPT-4.5 và GPT-5. Nhưng ông cho biết dự kiến chúng sẽ xuất hiện trong "vài tuần hoặc vài tháng tới".
        <br/><br/>
Cũng trong tuần này, Elon Musk đã đề nghị mua lại nhánh phi lợi nhuận của OpenAI với giá 97,4 tỷ USD, nhưng hội đồng quản trị OpenAI dự kiến sẽ từ chối lời đề nghị này.
        <br/><br/>
       Nguồn : hhttps://znews.vn/khi-nao-chatgpt-5-ra-mat-post1531335.html
       
       
        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `Nguồn cơn sự 'cay nghiệt' của Elon Musk dành cho Sam Altman`,
        path: "nguon-con-su-cay-nghiet-cua-elon-musk-danh-cho-sam-altman",
        title_content: `Musk tài trợ hàng chục triệu USD và cùng Altman thành lập phòng nghiên cứu phi lợi nhuận OpenAI vào 2015. Dù vậy khi thời thế thay đổi, Musk tuyên chiến với Altman và chính OpenAI.`,
        img: imgnew48,
        description: `    <p>
        Musk tài trợ hàng chục triệu USD và cùng Altman thành lập phòng nghiên cứu phi lợi nhuận OpenAI vào 2015. Dù vậy khi thời thế thay đổi, Musk tuyên chiến với Altman và chính OpenAI.
        <br/><br/>
        <img src=${imgnew48} alt"">
        <br/><br/>
        Vào ngày đầu tiên của nhiệm kỳ thứ hai dưới thời ông Trump, Elon Musk khi ấy đang có mặt trong khuôn viên Nhà Trắng, bất ngờ nhận được tin rằng đối thủ của ông sắp tổ chức một buổi họp báo cùng với tổng thống Mỹ.
        <br/><br/>
        Musk bật TV lên và chứng kiến cách Giám đốc điều hành OpenAI, Sam Altman rạng rỡ bên cạnh ông Trump quảng bá khoản đầu tư 500 tỷ USD vào cơ sở hạ tầng AI có tên Stargate.
        <br/><br/>
        Theo nguồn tin của WSJ, mặc dù trong Musk gần như luôn đi cùng hoặc giữ liên lạc với Trump trong vài tháng tranh cử, ông đã bị bất ngờ bởi thông báo này.
        <br/><br/>
        Điều khiến CEO Tesla cảm thấy bị tổn thương sâu sắc nhất chính là thành công của Altman trong việc “bước chân” vào thế giới của ông Trump, qua chuỗi các cuộc họp được sắp xếp cẩn thận ở Palm Beach và những cuộc gọi điện thoại với Nhà Trắng.
        <br/><br/>
        Nói cách khác, kế hoạch này đã được giữ bí mật khỏi “người bạn thân nhất” của tổng thống Mỹ.
        <br/><br/>
        Altman, Musk cùng thành lập phòng nghiên cứu phi lợi nhuận OpenAI vào 2015, với hàng chục triệu USD tài trợ đến từ Musk. Trong nhiều năm, Altman dựa vào danh tiếng và tài chính của Musk để đưa OpenAI đến thành công.
        <br/><br/>
        Cả 2 đã xây dựng "liên minh" nhằm ngăn chặn sự thống trị của Google trong cuộc đua trí tuệ nhân tạo (AI). Dù vậy khi thời thế thay đổi, Musk đã tuyên chiến với Altman và chính OpenAI.
        <br/><br/>
        Theo nhiều khía cạnh, Sam Altman (39 tuổi) và Elon Musk (53 tuổi) không thể khác nhau hơn. Trong khi Musk từng bị đánh đập và lăng mạ khi còn nhỏ, Altman lại là đứa con mẫu mực, được cha mẹ thường xuyên nhắc nhở rằng có thể trở thành bất cứ điều gì mình muốn.
        <br/><br/>
        Elon Musk thường có cách cư xử thô lỗ, Altman lại có xu hướng nói những gì người khác muốn nghe. Nếu Musk là một kỹ sư, say mê với các chi tiết trong thiết kế tên lửa và pin, Altman lại là một người đối lập, ám ảnh công nghệ, hiểu biết rộng rãi về triết học, khoa học và văn học, đồng thời viết những bài luận về cách thức tổ chức xã hội.
        <br/><br/>
        Tuy nhiên, cả Elon Musk và Sam Altman đều có chung một sở thích đáng chú ý: Quyền lực.
        <br/><br/>
        Người kết nối cho Musk và Altman là Geoff Ralston, đối tác của Y Combinator. Đây là "vườn ươm" startup nổi tiếng, từng hỗ trợ công ty đầu tiên của Altman - mạng xã hội dựa trên vị trí có tên Loopt.
        <br/><br/>
        Thời điểm đó, Altman vừa bán Loopt và chẳng biết nên lập công ty mới hay tiếp tục đầu tư. Trong khi đó, SpaceX của Musk đã phát triển tàu vũ trụ thương mại, có khả năng lấy hàng từ trạm vũ trụ rồi trở về Trái Đất. Tương quan khác biệt nhưng cả 2 nhanh chóng tìm được suy nghĩ chung.
        <br/><br/>
        Musk ngày càng lo lắng khi AI tiến bộ quá nhanh. Theo đơn kiện, nỗi sợ của vị tỷ phú bắt nguồn từ cuộc gặp năm 2012 với Demis Hassabis, đồng sáng lập công ty AI DeepMind, người "nhấn mạnh những mối nguy hiểm tiềm tàng mà sự tiến bộ của AI gây ra cho xã hội".
        <br/><br/>
        Tương tự Musk, Altman cũng lo lắng về sự nguy hiểm của AI. Tháng 2/2015, ông viết rằng AI "có lẽ là mối đe dọa lớn nhất với sự tồn tại liên tục của loài người".
        <br/><br/>
        Kể từ đó, Musk và Altman thường xuyên liên lạc thảo luận về AI. Tháng 3/2015, Altman hỏi ý kiến Musk về việc viết bức thư lên chính phủ Mỹ liên quan đến công nghệ này.
        <br/><br/>
        2 tháng sau, Altman gửi email cho Musk, đề xuất khởi động dự án chuyên về AI tại Y Combinator, có tên "Manhattan Project". Cả 2 làm việc với phòng thí nghiệm AI mới, được Musk gọi là OpenAI.  
        <br/><br/>

       Nguồn : https://znews.vn/nguon-con-su-cay-nghiet-cua-elon-musk-danh-cho-sam-altman-post1531857.html
        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `TikTok chính thức trở lại tại Mỹ`,
        path: "tiktok-chinh-thuc-tro-lai-tai-my-post",
        title_content: `TikTok chính thức xuất hiện trở lại trên hai kho ứng dụng App Store của Apple và Play Store của Google tại Mỹ, sau khi chính quyền Trump ký lệnh hành pháp trì hoãn lệnh cấm.`,
        img: imgnew47,
        description: `    <p>
        Chiều 12/2, tại Trụ sở Chính phủ, Thủ tướng Phạm Minh Chính tiếp ông Choi Joo Ho, Tổng Giám đốc Samsung Việt Nam và người kế nhiệm là ông Na Ki Hong.
        <br/><br/>
        <img src=${imgnew47} alt"">
        <br/><br/>
        Theo Bloomberg, Apple và Google đã khôi phục ứng dụng TikTok trên App Store và Play Store, sau khi Tổng chưởng lý Mỹ Pam Bondi cam đoan trong một bức thư rằng lệnh cấm sẽ không được thi hành ngay lập tức.
       <br/><br/>
        Điều này giúp người dùng smartphone có thể tải trở lại ứng dụng mạng xã hội video. TikTok đã hoạt động trở lại tại Mỹ vào ngày 19/1 nhưng vẫn bị ẩn trên các kho ứng dụng chính thức của iOS và Android.
       <br/><br/>
       TikTok buộc phải hành động trước thời hạn ngày 19/1 để công ty mẹ tại Trung Quốc bán ứng dụng tại Mỹ hoặc phải đối mặt với lệnh cấm hoạt động.
       <br/><br/>
       Tuy nhiên, công ty đã khôi phục ứng dụng sau khi nhận được "sự đảm bảo" từ ông Trump, người sau đó ban hành lệnh hành pháp đình chỉ việc thực thi luật yêu cầu bán TikTok.
       <br/><br/>
        Luật này cấm các công ty như Apple, Google hoặc Oracle phân phối hoặc duy trì ứng dụng TikTok - về cơ bản là ngăn chặn ứng dụng này hoạt động - nếu ByteDance chưa hoàn tất việc bán trước ngày 19/1.
       <br/><br/>
        Theo WSJ, một số công ty đã bày tỏ sự quan tâm đến việc tham gia vào thỏa thuận mua lại TikTok hoặc các hoạt động của nó tại Mỹ, bao gồm Oracle, Amazon và Microsoft.
       <br/><br/>
        Trong khi đó, TikTok và các đối tác của công ty đang thúc đẩy các giải pháp có thể tránh việc bán lại, một trong số đó là hồi sinh một hệ thống rào chắn bảo vệ dữ liệu của người dùng Mỹ.
       <br/><br/>
       Vào tháng 1, CEO TikTok Shou Chew đã gặp gỡ các quan chức cấp cao của Nhà Trắng và đề xuất một liên doanh với các nhà đầu tư Mỹ. Liên doanh mới này sẽ có trụ sở tại Mỹ nhằm giám sát bảo mật dữ liệu, với ban quản lý và hội đồng quản trị sẽ gồm đa số là người Mỹ.
       <br/><br/>
       Nguồn : https://znews.vn/tiktok-chinh-thuc-tro-lai-tai-my-post1531510.html
        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `Thủ tướng Phạm Minh Chính tiếp Tổng Giám đốc Samsung Việt Nam`,
        path: "thu-tuong-pham-minh-chinh-tiep-tong-giam-doc-samsung-viet-nam",
        title_content: `Chiều 12/2, tại Trụ sở Chính phủ, Thủ tướng Phạm Minh Chính tiếp ông Choi Joo Ho, Tổng Giám đốc Samsung Việt Nam và người kế nhiệm là ông Na Ki Hong.`,
        img: imgnew44,
        description: `    <p>
        Chiều 12/2, tại Trụ sở Chính phủ, Thủ tướng Phạm Minh Chính tiếp ông Choi Joo Ho, Tổng Giám đốc Samsung Việt Nam và người kế nhiệm là ông Na Ki Hong.
        <br/><br/>
        <img src=${imgnew44} alt"">
        <br/><br/>
        Từ năm 2014 đến nay, Tập đoàn đã nâng tổng số lượng nhà cung cấp là doanh nghiệp Việt Nam lên 306 doanh nghiệp. Cùng với đó, Samsung Việt Nam thực hiện nhiều dự án hỗ trợ hàng trăm doanh nghiệp công nghiệp phụ trợ Việt Nam về cải tiến doanh nghiệp, phát triển nhà máy thông minh, đào tạo chuyên gia tư vấn, chuyên gia khuôn mẫu...
       <br/><br/>
       Cảm ơn Chính phủ Việt Nam, mà trực tiếp là Thủ tướng Chính phủ đã luôn quan tâm, tạo điều kiện để Samsung đầu tư, sản xuất, kinh doanh hiệu quả tại Việt Nam, ông Choi Joo Ho và ông Na Ki Hong cho biết, cùng với đầu tư các lĩnh vực truyền thống, thời gian tới, Samsung tiếp tục mở rộng đầu tư tại Việt Nam trong các lĩnh vực mới, như trí tuệ nhân tạo, công nghiệp bán dẫn, tham gia tích cực quá trình chuyển đổi số tại Việt Nam..., đề nghị Thủ tướng Chính phủ và các bộ, ngành tiếp tục ủng hộ, hỗ trợ hoạt động đầu tư kinh doanh của Tập đoàn tại Việt Nam.
        <br/><br/>
        Hội đồng quản trị của OpenAI nêu trong thư rằng việc Musk sẵn sàng rút lại đề nghị nếu các điều kiện của ông được đáp ứng cho thấy đề nghị của Musk "hoàn toàn không phải là một đề nghị mua"
        <br/><br/>
        <img src=${imgnew45} alt"">
        <br/><br/>
        Đánh giá cao nỗ lực, quyết tâm và những kết quả kinh doanh của Tập đoàn Samsung cũng như sự đồng hành, hỗ trợ hiệu quả, tích cực của cá nhân Tổng giám đốc Choi Joo Ho trong 2 nhiệm kỳ công tác tại Việt Nam, qua đó đóng góp vào quá trình phát triển kinh tế - xã hội của Việt Nam, cũng như thúc đẩy quan hệ hợp tác, tình hữu nghị Việt Nam – Hàn Quốc, Thủ tướng Chính phủ Phạm Minh Chính mong muốn dù ở cương vị nào ông Choi Joo Ho tiếp tục đóng góp vào sự phát triển của Samsung Việt Nam, quá trình phát triển của Việt Nam và vun đắp cho quan hệ Việt Nam – Hàn Quốc.
        <br/><br/>
        Thủ tướng chúc mừng và đề nghị người kế nhiệm Tổng giám đốc Samsung Việt Nam Na Ki Hong tiếp nối, thực hiện hiệu quả các chiến lược, kế hoạch đầu tư tại Việt Nam mà Tập đoàn đã đặt ra, nhằm tận dụng thời cơ và đồng hành với đà phát triển của Việt Nam, đưa Samsung cùng Việt Nam phát triển trong thời gian tới.        <br/><br/>
       <br/><br/>
        Hoan nghênh và ủng hộ kế hoạch triển khai hoạt động sản xuất kinh doanh của Tập đoàn trong thời gian tới tại Việt Nam; trên nền tảng quan hệ Đối tác Chiến lược toàn diện giữa Việt Nam và Hàn Quốc đang phát triển mạnh mẽ, Thủ tướng Phạm Minh Chính khẳng định, Chính phủ Việt Nam tiếp tục đồng hành và tạo điều kiện thuận lợi để Tập đoàn đầu tư kinh doanh bền vững, hiệu quả và thành công tại Việt Nam, trên tinh thần “lợi ích hài hòa, rủi ro chia sẻ”, “cùng lắng nghe, thấu hiểu; cùng chia sẻ tầm nhìn và hành động; cùng làm, cùng hưởng, cùng thắng và cùng phát triển”.
       <br/><br/>
       Mong muốn có người Việt Nam trong ban lãnh đạo Samsung Việt Nam, Thủ tướng Chính phủ đề nghị Tập đoàn Samsung hỗ trợ Việt Nam nâng cao hơn nữa năng lực doanh nghiệp trong nước để có thể tham gia có hiệu quả hơn chuỗi sản xuất, chuỗi cung ứng toàn cầu của Tập đoàn; đẩy mạnh hợp tác để đưa các doanh nghiệp công nghệ số của Việt Nam trở thành đối tác trong hệ sinh thái của Samsung.
       <br/><br/>
        <img src=${imgnew46} alt"">
       <br/><br/>
       Thủ tướng đề nghị Samsung phối hợp chặt chẽ với các bộ, ngành, cơ quan của Việt Nam để triển khai thực chất và hiệu quả các trung tâm đào tạo đặt tại Trung tâm Đổi mới sáng tạo quốc gia Việt Nam; tăng cường đầu tư và mở rộng trung tâm R&D, hỗ trợ hoạt động khởi nghiệp và đổi mới sáng tạo tại Việt Nam; tiếp tục duy trì vị thế dẫn đầu, trở thành nhà đầu tư chiến lược, đẩy mạnh phát triển công nghiệp hỗ trợ, chip, bán dẫn, thúc đẩy phát triển khoa học công nghệ, đổi mới sáng tạo và chuyển đổi số tại Việt Nam.
       <br/><br/>
       Cho biết, Việt Nam tiếp tục cải thiện môi trường đầu tư, đẩy mạnh 3 đột phá chiến lược, phấn đầu đạt mức tăng trưởng ít nhất 8% trong năm 2025 và tăng trưởng 2 con số trong thời gian tới, Thủ tướng Chính phủ mong muốn và tin tưởng Samsung sẽ tăng trưởng tương xứng, cùng Việt Nam bước vào kỷ nguyên mới.
       <br/><br/>
        Nguồn : https://znews.vn/dong-chay-nhan-tai-dang-sau-thanh-cong-cua-deepseek-post1530666.html

        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `iPhone tại Trung Quốc sẽ rất khác 'bản quốc tế'`,
        path: "iphone-tai-trung-quoc-se-rat-khac",
        title_content: `Apple Intelligence tại Trung Quốc sẽ hoạt động khác với phần còn lại của thế giới, bao gồm chính sách kiểm duyệt nội dung..`,
        img: imgnew50,
        description: `    <p>
        Apple Intelligence tại Trung Quốc sẽ hoạt động khác với phần còn lại của thế giới, bao gồm chính sách kiểm duyệt nội dung..
        <br/><br/>
        <img src=${imgnew50} alt"">
        <br/><br/>
        Sau thời gian dài chờ đợi, Apple Intelligence dự kiến phát hành tại Trung Quốc vào tháng 5. Để đạt điều này, Táo khuyết phải hợp tác với công ty địa phương nhằm kiểm duyệt bộ công cụ.
        <br/><br/>
        Theo nguồn tin của Bloomberg, Apple thành lập các đội ngũ tại Trung Quốc và Mỹ để tinh chỉnh Apple Intelligence cho đất nước tỷ dân. Đây được xem là dự án phức tạp, đòi hỏi chỉnh sửa phần mềm và phụ thuộc chặt chẽ vào đối tác.
        <br/><br/>
        Apple đặt mục tiêu phát hành bộ công cụ AI tại Trung Quốc sớm nhất vào tháng 5. Ngoài vấn đề kỹ thuật, công ty còn đối mặt rào cản pháp lý. Nhìn chung, iPhone tại đất nước tỷ dân sẽ rất khác so với phần còn lại. 
        <br/><br/>
        Đây là công việc khiến Apple gặp nhiều áp lực, trong bối cảnh doanh số tại Trung Quốc sụt giảm mạnh…
        <br/><br/>
        Các thương hiệu nội địa sử dụng tính năng AI của riêng họ để thu hút người dùng iPhone chuyển sang. Việc phát hành công nghệ một cách phù hợp rất quan trọng để Apple lấy lại vị thế tại quốc gia này”, nhà phân tích Mark Gurman nhấn mạnh.
        <br/><br/>
        Trong một động thái hiếm hoi, Apple phải dựa vào Alibaba và Baidu. Công ty hợp tác với Alibaba nhằm phát triển hệ thống phân tích, sửa đổi mô hình AI (hoạt động trực tiếp trên máy) cho người dùng iPhone, iPad và Mac tại Trung Quốc.        <br/><br/>
        <br/><br/>
        Hệ thống của Alibaba sẽ kiểm duyệt và lọc kết quả AI theo tiêu chuẩn từ chính phủ Trung Quốc. Trong khi đó, Baidu là đối tác phụ trợ nhằm xử lý những tính năng khác.
        <br/><br/>
        Phát hành Apple Intelligence tại Trung Quốc không hề đơn giản. Tại Mỹ, nền tảng này có 3 nhóm tính năng chính: chạy trực tiếp trên thiết bị (mô hình của Apple), chạy trên máy chủ Apple, và chatbot sử dụng cơ sở hạ tầng OpenAI.
        <br/><br/>
        Khi đến Trung Quốc, các tính năng hoạt động trực tiếp trên thiết bị vẫn dùng mô hình AI do Apple tự phát triển. Tuy nhiên, kết quả cho ra được kiểm duyệt bởi phần mềm Alibaba.
        <br/><br/>
        Theo Bloomberg, chính phủ Trung Quốc có thể chỉ đạo Alibaba, yêu cầu Apple sửa mô hình AI nếu kết quả có vấn đề. Trong thời gian chỉnh sửa, Apple sẽ tạm thời tắt tính năng AI đến khi nội dung được kiểm duyệt.
        <br/><br/>
        “Bên ngoài Trung Quốc, mô hình AI trên thiết bị Apple có thể cập nhật âm thầm, nhưng quá trình này tại Trung Quốc sẽ diễn ra thường xuyên và nhanh chóng”, Gurman viết.
        <br/><br/>
        Nguồn : https://znews.vn/iphone-tai-trung-quoc-se-rat-khac-post1531682.html

        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `OpenAI chính thức từ chối Elon Musk`,
        path: "elon-musk-pha-ke-hoach-cua-openai-post1530607",
        title_content: `Hội đồng quản trị của OpenAI không chấp nhận lời đề nghị trị giá 97,4 tỷ USD của tỷ phú Elon Musk để mua lại các tài sản của công ty.`,
        img: imgnew43,
        description: `    <p>
       Hội đồng quản trị của OpenAI không chấp nhận lời đề nghị trị giá 97,4 tỷ USD của tỷ phú Elon Musk để mua lại các tài sản của công ty.
        <br/><br/>
        <img src=${imgnew43} alt"">
        <br/><br/>
        Lời từ chối được luật sư của OpenAI đưa ra hôm 14/2, cho rằng lời đề nghị của Elon Musk không đem đến lợi ích đối với nhiệm vụ của OpenAI.
       <br/><br/>
       Chủ tịch OpenAI, ông Bret Taylor cũng khẳng định công ty này "không phải để bán, và hội đồng quản trị đồng thuận từ chối nỗ lực của ông Musk để phá đối thủ cạnh tranh".
        <br/><br/>
        Hội đồng quản trị của OpenAI nêu trong thư rằng việc Musk sẵn sàng rút lại đề nghị nếu các điều kiện của ông được đáp ứng cho thấy đề nghị của Musk "hoàn toàn không phải là một đề nghị mua"
        <br/><br/>
       Luật sư của Musk, Marc Toberoff, cho biết hội đồng quản trị đáng lẽ phải xem xét đề nghị một cách thiện chí bởi vì việc chuyển đổi thành công ty lợi nhuận về cơ bản có nghĩa là tài sản của tổ chức phi lợi nhuận đang được đem ra bán.
        <br/><br/>
       "Họ chỉ đang bán nó cho chính họ với giá thấp hơn nhiều so với những gì Musk đã đề nghị", ông nói, cáo buộc hội đồng quản trị thực hiện một "giao dịch tư lợi điển hình".
        <br/><br/>
       Đầu tuần này, nhóm các nhà đầu tư do Elon Musk đứng đầu đề nghị trả 97,4 tỷ USD để mua lại tổ chức phi lợi nhuận kiểm soát OpenAI.
        <br/><br/>
            Hiện tại, cả Sam Altman và Elon Musk đều đấu tranh tại tòa án về định hướng của OpenAI.       
        <br/><br/>
        “Đã đến lúc OpenAI quay trở lại với mã nguồn mở, tập trung vào an toàn như mục tiêu trước đây. Chúng tôi sẽ đảm bảo điều đó”, Elon Musk cho biết.
        <br/><br/>
        Altman và Musk đồng sáng lập OpenAI vào năm 2015 với tư cách là một tổ chức phi lợi nhuận. Vào năm 2019, sau khi CEO Tesla rời khỏi công ty và Altman trở thành giám đốc điều hành, doanh nghiệp này đã tạo ra một công ty con đóng vai trò là phương tiện để gọi vốn. 
        <br/><br/>
        Khi ChatGPT thành công ngoài mong đợi, Altman muốn tách công ty con ra khỏi tổ chức phi lợi nhuận để thuận lợi cho các hoạt động kinh doanh trong tương lai. Tuy nhiên, một trong những câu hỏi hóc búa nhất trong quá trình chuyển đổi là vấn đề định giá.
        <br/><br/>
        Trước động thái mới nhất của Musk, OpenAI đã phải đối mặt với vô số trở ngại. Rival Meta Platforms đã gửi một lá thư cho tổng chưởng lý California vào tháng 12/2024 và bày tỏ sự phản đối của mình đối với kế hoạch chuyển đổi của ông lớn ngành AI.
        <br/><br/>
        Hiện tại, công ty đứng sau ChatGPT đang bị kẹt trong các cuộc đàm phán với Microsoft và nhiều bên liên quan khác về vốn chủ sở hữu trong công ty mới.
        <br/><br/>
        OpenAI cam kết hoàn tất quá trình chuyển đổi vào cuối năm 2026 như một phần của vòng gọi vốn trị giá 6,6 tỷ USD vào tháng 10/2024, với mức định giá công ty là 157 tỷ USD.
        <br/><br/>
        Tháng 1, Wall Street Journal đưa tin rằng công ty đang trong quá trình đàm phán riêng để huy động tới 40 tỷ USD, qua đó nâng định giá lên mức 300 tỷ USD. Tập đoàn SoftBank của Nhật Bản sẽ dẫn đầu vòng gọi vốn này và đang trong quá trình thương thảo để đầu tư 15-25 tỷ USD.
        <br/><br/>
        Nguồn : https://znews.vn/dong-chay-nhan-tai-dang-sau-thanh-cong-cua-deepseek-post1530666.html

        </p>`,
        categoryID: 1
    },
    {
        id: 1,
        title: `Dòng chảy nhân tài đằng sau thành công của DeepSeek`,
        path: "dong-chay-nhan-tai-dang-sau-thanh-cong-cua-deepseek",
        title_content: `Nhiều nhân sự chủ chốt của DeepSeek được phát triển ngoài Trung Quốc, nhưng bỏ qua Thung lũng Silicon để trở về quê hương.`,
        img: require("../images/news/events/Race_Deepseek.png"),
        description: `    <p>
        Nhiều nhân sự chủ chốt của DeepSeek được phát triển ngoài Trung Quốc, nhưng bỏ qua Thung lũng Silicon để trở về quê hương.  
        <br/><br/>
        <img src=${imgnew41} alt"">
        <br/><br/>
        Sau những thành công gây chấn động giới công nghệ toàn cầu, đội ngũ phát triển ra DeepSeek vẫn là bí ẩn với thế giới. Ngoài người sáng lập Liang Wenfeng, những nhân sự đứng sau rất ít xuất hiện. Mới đây, RestofWorld tìm ra hồ sơ của một số nhân tài chủ chốt trong nhóm này. Đa số họ được đào tạo ở Trung Quốc,, phát triển ở nước ngoài rồi quay về phục vụ dự án quê hương.
       <br/><br/>
      Ví dụ, Pan Zizheng từng thực tập tại Nvidia và nhận được lời mời làm việc toàn thời gian. Tuy nhiên, theo người hướng dẫn của anh, Pan đưa ra quyết định gia nhập DeepSeek mà không chút do dự, trở thành nhân sự cốt cán cho các mô hình DeepSeek-VL2, DeepSeek-R1.
        <br/><br/>
        Hồi tháng 1, Liang Wenfeng cũng tham dự cuộc họp được chủ trì bởi Thủ tướng Trung Quốc Lý Cường, tập hợp những cá nhân kiệt xuất của nước này trong nhiều lĩnh vực.
        <br/><br/>
        Trong khi đó, Junxiao Song tốt nghiệp Đại học Khoa học Công nghệ Hong Kong. Sau khi gia nhập công ty, anh phát triển thuật toán máy học tăng cường GRPO, được áp dụng để đào tạo R1. Người này đóng vai trò quan trọng trong việc training hiệu quả, chắt lọc mô hình và công việc khác của dự án.
        <br/><br/>
        DeepSeek bắt đầu gây chú ý khi ra mắt mã nguồn mở mô hình ngôn ngữ lớn có khả năng suy luận với chi phí rẻ hơn mức chung của ngành hồi giữa năm 2024. Hôm 20/1, DeepSeek R1 được giới thiệu, với điểm chuẩn vượt qua bản o1 trả phí từ OpenAI.
        <br/><br/>
       “Bằng cách nào đó, DeepSeek đã thu hút được những tài năng sáng giá nhất”, giáo sư hướng dẫn của Junxiao Song than thở.
        <br/><br/>
        <img src=${imgnew42} alt"">
        <br/><br/>
        Đội ngũ nhân sự của DeepSeek cũng phản ánh một dòng chảy mới của giới công nghệ toàn cầu. Tài năng nội địa Trung Quốc được đào tạo cơ bản trong nước, được phát triển khi du học và tham gia với các công ty ngoại quốc, chọn trở về thay vì ở lại Mỹ. “Có nhiều tài năng xuất chúng ở Trung Quốc, những người không cần làm việc ở công ty Mỹ để thành công”, Zhiding Yu, người từng hướng dẫn Pan Zizheng tại Nvidia đăng trên X.
        <br/><br/>
        Theo báo cáo của MarcoPolo vào năm 2023, một nửa nhân sự AI toàn cầu từng được đào tạo tại Trung Quốc. Chính sách kém cởi mở với người nhập cư khiến lựa chọn ở lại Thung lũng Silicon không còn hấp dẫn. Ngoài ra, thu nhập tăng lên cùng môi trường sống quen thuộc cũng là điểm cộng thuyết phục họ trở lại quê nhà.
        <br/><br/>
         Ví dụ với DeepSeek, Liang Wenfeng nói trong một cuộc phỏng vấn rằng họ không giống các công ty công nghệ khác, khi không thúc đẩy cạnh tranh nội bộ hay ép làm thêm giờ. Doanh nghiệp trao cho nhân viên quyền riêng tư và tự do trong việc lựa chọn nhiệm vụ.
        <br/><br/>
        Chiến lược phát triển mã nguồn mở, phục vụ cộng đồng mang lại cho đội ngũ cảm giác tự hào và nâng cao danh tiếng của công ty.
        <br/><br/>
        
        Nguồn : https://znews.vn/dong-chay-nhan-tai-dang-sau-thanh-cong-cua-deepseek-post1530666.html

        </p>`,
        categoryID: 1
    },
    {
        id: 2,
        title: `Nhà sáng lập DeepSeek và Jack Ma được mời gặp lãnh đạo Trung Quốc`,
        path: "nha-sang-lap-deepseek-va-jack-ma-duoc-moi-gap-lanh-dao-trung-quoc",
        title_content: `Jack Ma, đồng sáng lập Alibaba và nhà sáng lập DeepSeek, Liang Wenfeng là hai trong số nhiều doanh nhân nổi bật được Trung Quốc mời dự hội nghị.`,
        img: require("../images/news/events/deepseak.png"),
        description: `    <p>
        Jack Ma, đồng sáng lập Alibaba và nhà sáng lập DeepSeek, Liang Wenfeng là hai trong số nhiều doanh nhân nổi bật được Trung Quốc mời dự hội nghị.
        <br/><br/>
        <img src=${imgnew40} alt"">
        <br/><br/>
        Theo Bloomberg, Trung Quốc đã mời các doanh nhân nổi bật, trong đó có đồng sáng lập Alibaba Jack Ma và nhà sáng lập DeepSeek, Liang Wenfeng đến gặp gỡ các lãnh đạo hàng đầu của quốc gia.
       <br/><br/>
       Cuộc họp nhiều khả năng sẽ diễn ra vào tuần tới, đánh dấu sự ủng hộ của Trung Quốc dành cho khu vực tư nhân sau nhiều năm biến động. Mặc dù các chi tiết khác còn khá ít ỏi, nguồn tin cho biết Chủ tịch Trung Quốc Tập Cận Bình dự kiến sẽ tham dự hội nghị này.
        <br/><br/>
        Hồi tháng 1, Liang Wenfeng cũng tham dự cuộc họp được chủ trì bởi Thủ tướng Trung Quốc Lý Cường, tập hợp những cá nhân kiệt xuất của nước này trong nhiều lĩnh vực.
        <br/><br/>
        Theo 168, Liang Wenfeng sinh năm 1980, tại Ngô Xuyên, Trạm Giang, Quảng Đông, Trung Quốc. Năm 2002, ông được nhận vào Đại học Chiết Giang, chuyên ngành Kỹ thuật Thông tin Điện tử, thủ khoa ở Ngô Xuyên.
        <br/><br/>
        DeepSeek bắt đầu gây chú ý khi ra mắt mã nguồn mở mô hình ngôn ngữ lớn có khả năng suy luận với chi phí rẻ hơn mức chung của ngành hồi giữa năm 2024. Hôm 20/1, DeepSeek R1 được giới thiệu, với điểm chuẩn vượt qua bản o1 trả phí từ OpenAI.
        <br/><br/>
       Theo TechCrunch, R1 vượt trội o1 của OpenAI trên các tiêu chuẩn đánh giá như AIME, MATH-500 và SWE-bench Verified. Đồng thời, một trong những mô hình của họ chỉ tốn 5,6 triệu USD, so với hàng trăm triệu USD mà các công ty hàng đầu của Mỹ phải chi để huấn luyện các mô hình của mình.
        <br/><br/>
        Trong khi đó, trong mắt nhiều người, Jack Ma là biểu tượng tinh thần của Alibaba và đại diện tiêu biểu cho tinh thần khởi nghiệp Trung Quốc.
        <br/><br/>
        Nguồn : https://znews.vn/nha-sang-lap-deepseek-va-jack-ma-duoc-moi-gap-lanh-dao-trung-quoc-post1531604.html
        </p>`,
        categoryID: 1
    },
   
    
    
    {
        id: 9,
        title: `Bế tắc vì bị ngành Y 'cướp' sinh viên`,
        path: "be-tac-vi-bi-nganh-y-cuop-sinh-vien",
        title_content: `Các khoa, trường đào tạo công nghệ, kỹ thuật ở Hàn Quốc bất lực vì sinh viên đồng loạt "quay xe" để học ngành Y, khiến tình trạng thiếu hụt nhân tài của ngành thêm trầm trọng.`,
        img:imgnew24,
        description: `<p>
        <span style="font-size: 2rem;">Các khoa, trường đào tạo công nghệ, kỹ thuật ở Hàn Quốc bất lực vì sinh viên đồng loạt "quay xe" để học ngành Y, khiến tình trạng thiếu hụt nhân tài của ngành thêm trầm trọng.</span>
        <br/><br/>
        <img src=${imgnew24} alt="">
        <br/><br/>
        Khi các trường đào tạo công nghệ và kỹ thuật đang nổi lên như một hiện tượng ở các cường quốc về chip như Mỹ và Trung Quốc, ngành công nghiệp bán dẫn ở Hàn Quốc lại phải vật lộn vì thiếu người học. Các chuyên gia trong lĩnh vực bán dẫn cho rằng trường y đang "hút cạn" nhân tài trong nước.
        <br/><br/>
        Giáo sư Lee Jong-hwan tại Đại học Sangmyung là một trong những người đưa ra nhận định đó. Ông cho rằng ở Hàn Quốc, những nhân tài hàng đầu đều bị trường y kéo về. Thậm chí, ở các trường đại học danh tiếng, những người đã thi đậu cũng muốn thi lại để đăng ký vào trường y.
        <br/><br/>
        Trong khi đó, ở Hàn Quốc, lượng sinh viên theo đuổi lĩnh vực công nghệ ngày một giảm. Số liệu mới nhất năm 2024 từ viện giáo dục tư nhân Jongro Hagwon cho thấy tỷ lệ bỏ học ở 5 khoa đào tạo bán dẫn hàng đầu Hàn Quốc lên đến 179%, nghĩa là số lượng sinh viên trúng tuyển chọn bỏ học cao gần gấp đôi số lượng suất học còn trống.
        <br/><br/>
        Mặc dù các công ty hàng đầu như Samsung Electronics và SK hynix luôn đảm bảo sinh viên ngành này có được việc làm sau tốt nghiệp, những sinh viên giỏi nhất vẫn từ bỏ suất học để chuyển sang ngành Y
        <br/><br/>
        Trong bối cảnh ngành công nghiệp bán dẫn ngày càng cạnh tranh gay gắt trên toàn cầu, việc đảm bảo giữ chân nhân tài trở thành ưu tiên hàng đầu của ngành này tại Hàn Quốc.
        <br/><br/>
        Nhưng theo Bộ Thương mại, Công nghiệp và Năng lượng, Hàn Quốc dự kiến đối mặt với tình trạng thiếu hụt khoảng 56.000 kỹ sư vi mạch vào năm 2031
        <br/><br/>
        Ông Ahn Cheol-soo, thành viên của Đảng Quyền lực Nhân dân, cũng bày tỏ mối lo ngại tương tự, đồng thời cho biết Trung Quốc đầu tư hơn 1,2 tỷ USD cho AI trong 5 năm tới, Mỹ cũng cam kết đầu tư 500 tỷ USD. Trong khi đó, khoản đầu tư của Hàn Quốc trong năm 2024 chỉ đạt 1,2 triệu USD.
        <br/><br/>
        "Làm sao chúng ta có thể bắt kịp nước bạn? Trung Quốc có hơn 410.000 nhà nghiên cứu AI, Mỹ cũng có hơn 200.000 người, trong khi Hàn Quốc có chưa đến 20.000 nhà nghiên cứu", ông Ahn đặt câu hỏi.
        <br/><br/>
        Trước khi theo đuổi sự nghiệp chính trị, ông Ahn Cheol-soo từng là một bác sĩ và doanh nhân trong lĩnh vực công nghệ. Vì thế, ông hiểu rằng AI là công nghệ chính, dẫn đầu cuộc cách mạng công nghiệp lần thứ tư. Nếu tụt hậu ở thời điểm hiện tại, Hàn Quốc sẽ dần suy thoái.
        <br/><br/>
        Nguồn : https://lifestyle.znews.vn/be-tac-vi-bi-nganh-y-cuop-sinh-vien-post1531050.html
        </p>`,
        categoryID: 2
    },
    {
        id: 10,
        title: `ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Phú Quốc`,
        path: "icar-viet-nam-to-chuc-ky-niem-5-nam-thanh-lap-tai-phu-quoc",
        title_content: `Sự kiện lớn nhất trong ngành nội thất ô tô. ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú quốc với sự góp mặt của hơn 200 CBNV và các đại lý thân thiết.`,
        img: require("../images/news/events/image2-1667358253-205-width2000height2000.jpg"),
        description: `<p>
        <span style="font-size: 2rem;">Sự kiện lớn nhất trong ngành nội thất ô tô. ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú quốc với sự góp mặt của hơn 200 CBNV và các đại lý thân thiết.</span>
        <br/><br/>
        <img src=${imgnew26} alt="">
        <br/><br/>
        ICAR Việt Nam tổ chức kỷ niệm 5 năm thành lập tại Vinpearl Phú Quốc. Trong 5 năm thành lập, ICAR đã ghi dấu bằng những bước đột phá đầy ấn tượng và đã tạo ra một luồng gió mới trong ngành nội thất ô tô. Những sản phẩm của ICAR luôn được khách hàng đánh giá cao, đặc biệt là những tính năng hoàn toàn mới, đáp ứng xu hướng hiện đại.
        <br/><br/>
        Thành công của ICAR Việt Nam luôn gắn liền với lực lượng R&D, ngay từ những ngày đầu thành lập tầm nhìn của ICAR Việt Nam được ban lãnh đạo định hình rất rõ ràng, đó chính là việc làm chủ sản phẩm và phát triển sản phẩm phù hợp với nhu cầu của khách hàng. Đến nay, trung tâm R&D của ICAR Việt Nam đã quy tụ nhiều tài năng trẻ, là động lực và sức mạnh cho những thành công tiếp theo của kế hoạch 5 năm lần thứ hai.
        <br/><br/>
        <span style="font-size: 2rem;">Những dấu ấn của ICAR trong 5 năm đầu tiên</span>
        <br/><br/>
        ICAR Việt Nam đã phát triển từ một công ty nhỏ đến nay số lượng nhân sự công ty đã lên đến 200 CBNV. Đến nay ICAR Việt Nam có hơn 1000 đại lý phân phối chính hãng trên toàn quốc trải dài khắp 63 tỉnh thành. ICAR Việt Nam đã xây dựng các trung tâm bảo hành và chăm sóc khách hàng chính hãng tại Hà Nội, HCM, Đà Nẵng, Vinh. Và tiếp đến sẽ xây dựng thêm nhiều trung tâm bảo hành trên toàn quốc để phục vụ các khách hành nhanh chóng nhất.
        <br/><br/>
        ICAR Việt Nam là đơn vị có chế độ bảo hành dài lâu nhất và tốt nhất trên thị trường xe hơi. Với dòng sản phẩm android box có thời gian bảo hành lên đến 6 năm, gấp 2,5 lần so với thị trường. Màn hình DVD Android ô tô có thời gian bảo hành lên đến 5 năm, cảm biến áp suất lốp đa số 3 năm và camera 360 là 2 năm. Cùng với đó là chính sách hỗ trợ người dùng tối đa, mang đến những trải nghiệm tốt nhất như lỗi 1 đổi 1 trong vòng 1 năm và dùng thử 30 ngày.
        <br/><br/>
        Sau 5 năm phát triển, ICAR Việt Nam đã là cái tên quen thuộc đối với khách hàng Việt. Luôn được sự tin tưởng của tất cả các nhà phân phối và khách hàng trên toàn quốc.
        <br/><br/>
        Quay lại sự kiện kỷ niệm 5 năm thành lập và làm hội nghị khách hàng tại Phú Quốc. Với lịch trình 3 ngày 2 đêm từ ngày 3/11/2022 đến ngày 5/11/2022 đây được coi là sự kiện lớn nhất của ICAR.
        <br/><br/>
        Nhân sự kiện kỷ niệm 5 năm này, tối ngày 4/11/2022 - Gala night, ICAR Việt Nam cũng giới thiệu với các quý khách hàng, đại lý một sản phẩm mới. Hứa hẹn sẽ mang đến sự bùng nổ trong ngành nội thất ô tô.
        <br/><br/>
        <img src=${imgnew27} alt="">
        <br/><br/>
        Sự kiện Gala night tối ngày 4/11/2022 kỷ niệm 5 năm thành lập và ra mắt sản phẩm mới được livestream trực tiếp trên fanpage của ICAR Việt Nam. Quý vị và bạn đọc có thể theo dõi tại:  <a style="color: black; font-weight: 700;text-decoration: none;" href="https://www.facebook.com/icarvietnamvn">https://www.facebook.com/icarvietnamvn</a>
        <br/><br/>
        <img src=${imgnew28} alt="">
        <br/><br/>
        <span style="font-size: 2rem;">Review về đảo Phú Quốc - điểm đến của ICAR Việt Nam</span>
        <br/><br/>
        Đảo Phú Quốc từ lâu đã nổi tiếng là hòn đảo xinh đẹp với phong cảnh thiên nhiên yên bình, hoang sơ, khí hậu thuận lợi cho hoạt động vui chơi, nghỉ dưỡng, Phú Quốc còn là điểm đến lý tưởng cho nhiều hoạt động khám phá thiên nhiên kỳ thú. Đảo Phú Quốc cũng là một trong những điểm du lịch nổi tiếng thu hút nhiều du khách quốc tế và trong nước nhất tại Việt Nam.
        <br/><br/>
        <img src=${imgnew29} alt="">
        <br/><br/>
        Có thể thấy, đây được coi là sự kiện lớn nhất trong ngành nội thất ô tô. Với sự đầu tư lớn từ ICAR Việt Nam chắc chắn sẽ thu hút được rất nhiều sự quan tâm của giới nội thất. Và đặc biệt, giới xe hơi đang rất ngóng chờ sản phẩm mới của ICAR Việt Nam sắp được ra mắt trên thị trường ô tô.
        <br/><br/>
        Sự kiện kỷ niệm 5 năm thành lập - hội nghị khách hàng - ra mắt sản phẩm mới của ICAR Việt Nam sẽ được tổ chức trong 3 ngày từ 3/11/2022 đến 5/11/2022 tại Vinpearl Phú Quốc. Hãy cùng theo dõi sự kiện chi tiết trên fanpage ICAR Việt Nam nhé.
        </p>`,
        categoryID: 2
    },
    {
        id: 11,
        title: `Tân Phó cục trưởng Cục Cảnh sát phòng chống tội phạm về ma túy là ai?`,
        path: "tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai",
        title_content: `Ngày 2/11, tại Hà Nội, Cục Cảnh sát điều tra tội phạm về ma túy (C04) tổ chức lễ công bố quyết định của Bộ trưởng Bộ Công an về công tác cán bộ. Buổi lễ do Thiếu tướng Nguyễn Văn Viện, Cục trưởng C04 chủ trì.`,
        img: require("../images/news/events/1667402850-a44b9c0efb1ced4470623d85ee080f5b-width700height393.jpg"),
        description: `<p>
        Ngày 2/11, tại Hà Nội, Cục Cảnh sát điều tra tội phạm về ma túy (C04) tổ chức lễ công bố quyết định của Bộ trưởng Bộ Công an về công tác cán bộ. Buổi lễ do Thiếu tướng Nguyễn Văn Viện, Cục trưởng C04 chủ trì.
        <br/><br/>
        Tại buổi lễ, thừa ủy quyền của lãnh đạo Bộ Công an, Thiếu tướng Nguyễn Văn Viện đã trao quyết định của bộ trưởng Bộ Công an về việc điều động Đại tá Hoàng Quốc Việt giữ chức vụ Phó Cục trưởng C04 từ ngày 1/11.
        <br/><br/>
        <img src=${imgnew30} alt="">
        <br/><br/>
        Đại tá Hoàng Quốc Việt tại buổi lễ
        <br/><br/>
        Phát biểu nhận nhiệm vụ, Đại tá Hoàng Quốc Việt cho biết, đây là niềm vinh dự nhưng cũng là trọng trách lớn. Đại tá Việt hứa sẽ cùng tập thể Cục C04 đoàn kết, gắn bó, hoàn thành xuất sắc nhiệm vụ được giao.
        <br/><br/>
        Hiện Cục Cảnh sát điều tra tội phạm về ma túy có 1 Cục trưởng và 7 Phó cục trưởng.
        <br/><br/>
        Được biết, Đại tá Hoàng Quốc Việt sinh năm 1975 ở Nam Định. Ông Việt từng trải qua nhiều vị trí công tác khác nhau.
        <br/><br/>
        Đại tá Việt có nhiều năm làm cán bộ điều tra tại Công an Sơn La, Phó trưởng Công an huyện Mộc Châu, lãnh đạo một số phòng rồi đến Phó giám đốc Công an tỉnh Sơn La.
        <br/><br/>
        Ông Việt từng trực tiếp chỉ đạo, tham gia đấu tranh phá thành công nhiều chuyên án lớn về ma túy, hình sự trên địa bàn tỉnh Sơn La.
        <br/><br/>
        Nguồn:<a style="color: black;text-decoration: none;" target="_blank" href="https://www.baogiaothong.vn/tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai..">https://www.baogiaothong.vn/tan-pho-cuc-truong-cuc-canh-sat-phong-chong-toi-pham-ve-ma-tuy-la-ai...</a>
        </p>`,
        categoryID: 2
    },
    {
        id: 12,
        title: `Giá cổ phiếu Thaiholdings rơi hơn 85% từ đầu năm`,
        path: "gia-co-phieu-thaiholdings-roi-hon-85-tu-dau-nam",
        title_content: `Thị giá cổ phiếu THD của Thaiholdings trên thị trường chứng khoán đang rơi theo đà giảm của lợi nhuận, hiện đã xuống vùng thấp nhất kể từ năm 2020.`,
        img: require("../images/news/events/thaiholdings_tower.jpeg"),
        description: `<p>
        Thị giá cổ phiếu THD của Thaiholdings trên thị trường chứng khoán đang rơi theo đà giảm của lợi nhuận, hiện đã xuống vùng thấp nhất kể từ năm 2020.
        <br/><br/>
        Thị giá cổ phiếu THD đã giảm hơn 85% từ đầu năm. Ảnh: THD.
        <br/><br/>
        <img src=${imgnew31} alt="">
        <br/><br/>
        Công ty CP Thaiholdings (THD) vừa công bố báo cáo tài chính quý III với kết quả doanh thu và lợi nhuận giảm mạnh so với cùng kỳ năm 2021.
        <br/><br/>
        Cụ thể, tính trong ba tháng gần nhất, doanh nghiệp này ghi nhận gần766 tỷ đồngdoanh thu thuần, thấp hơn 74% so với cùng kỳ năm trước. Đây là nguyên nhân chính khiến lãi gộp công ty thu về chỉ tương đương gần 1/5 so với cùng kỳ, đạt hơn32 tỷ đồng.
        <br/><br/>
        Sau khi hợp nhất doanh thu với hoạt động tài chính cùng một số hoạt động khác và trừ các chi phí phát sinh, Thaiholdings thu về khoản lãi trước thuế62,5 tỷ đồng, cũng thấp hơn 43% so với năm 2021. Lợi nhuận sau khi trừ thuế đạt hơn39 tỷ đồng, nhưng cũng chỉ tương đương 1.2 so với quý III/2021.
        <br/><br/>
        Đáng chú ý, đây đã là quý suy giảm lợi nhuận thứ ba liên tiếp của Thaiholdings, đồng thời là mức thấp nhất mà doanh nghiệp này ghi nhận được kể từ giữa năm 2020 đến nay.
        <br/><br/>
        Thaiholdings cho biết nguyên nhân khiến lợi nhuận hợp nhất giảm mạnh trong quý vừa qua là do mức nền so sánh trong quý III/2021 ở mức cao. Bên cạnh đó, năm nay công ty cũng không còn phát sinh hoạt động chuyển nhượng dự án nên lợi nhuận khác của công ty đã giảm mạnh so với cùng kỳ.
        <br/><br/>
        Trong quý III năm trước, khoản lợi nhuận khác mang về cho Thaiholdings tới hơn180 tỷ đồng, trong khi quý III năm nay chỉ là gần 5,4 tỷ.
        <br/><br/>
        Tính chung 9 tháng từ đầu năm, doanh nghiệp có trụ sở tại số 210 Trần Quang Khải, quận Hoàn Kiếm, Hà Nội đã ghi nhận3.424 tỷ đồngdoanh thu thuần và báo lãi sau thuế 256 tỷ, giảm lần lượt 43% và 46% so với năm 2021.
        <br/><br/>
        Trên bảng cân đối kế toán, tính đến cuối tháng 9 năm nay, tổng tài sản của Thaiholdings vào khoảng8.500 tỷ đồng, giảm gần 20% so với cuối năm 2021. Nguyên nhân chủ yếu do phần tài sản cố định hữu hình đã giảm mạnh từ mức hơn1.000 tỷ đồngđầu năm xuống còn25 tỷ đồnghiện tại (do không còn hợp nhất báo cáo kết quả kinh doanh của một số công ty con).
        <br/><br/>
        Ngoài ra, phần tài sản dài hạn khác của Thaiholdings cũng giảm từ hơn5.200 tỷ đồngxuống 3.100 tỷ do không còn hạch toán gần1.960 tỷ đồngphần chi phí nhận chuyển nhượng quyền sử dụng đất tại Công ty Tôn Đản (chủ sở hữu tòa nhà Thaiholdings Tower).
        <br/><br/>
        Thị giá cổ phiếu THD trượt dài theo đà suy giảm lợi nhuận của công ty. Nguồn: Tradingview.
        <br/><br/>
        <img src=${imgnew32} alt="">
        <br/><br/>
        Đáng chú ý, đi cùng đà suy giảm lợi nhuận kể trên, thị giá cổ phiếu THD cũng đang trượt dài trên thị trường chứng khoán. Từ vùng giá trên 260.000 đồng/cổ phiếu hồi đầu năm, hiện THD chỉ còn được giao dịch với giá 38.500 đồng/đơn vị (cuối ngày 31/10). Như vậy, thị giá cổ phiếu này đã giảm ròng hơn 85% từ đầu năm.
        <br/><br/>
        Xét trên biểu đồ hàng ngày, thị giá THD cũng có diễn biến cùng chiều với lợi nhuận Thaiholdings thu về hàng quý khi đều đạt đỉnh vào quý IV/2021 và giảm mạnh từ đó đến nay. Hiện thị giá cổ phiếu này cũng đã giảm về mức thấp nhất 2 năm, trùng với đà rơi của lợi nhuận.
        <br/><br/>
        Thaiholdings hiện cũng ghi nhận một khoản bất động sản đầu tư trị giá gần105 tỷ đồng, là phần nguyên giá của 68 lô đất tại Khu đô thị Xuân Thành, phường Ninh Khánh, TP Ninh Bình được công ty nắm giữ với mục đích chờ tăng giá để bán. Trong đó, 63 lô đất này đang được dùng để thế chấp, cầm cố đảm bảo cho các khoản vay của công ty.
        <br/><br/>
        Thaiholdings cho biết giá trị hợp lý của các bất động sản này chưa được đánh giá và xác định một cách thức tại ngày 30/9. Tuy nhiên, dựa trên tình hình cho thuê và giá thị trường, ban lãnh đạo tin rằng giá trị hợp lý của các bất động sản này lớn hơn giá trị ghi nhận trên sổ.
        <br/><br/>
        Nguồn: Zingnews.vn
        </p>`,
        categoryID: 2
    },
    {
        id: 13,
        title: `Chủ tịch nước: Việt Nam coi khoa học công nghệ là quốc sách hàng đầu`,
        path: "chu-tich-nuoc-viet-nam-coi-khoa-hoc-cong-nghe-la-quoc-sach-hang-dau",
        title_content: `Việt Nam coi khoa học công nghệ là quốc sách hàng đầu, động lực then chốt cho phát triển công nghiệp hiện đại, tăng trưởng cao, bền vững, Chủ tịch nước khẳng định.`,
        img: require("../images/news/events/-6758-1663337799.jpg"),
        description: `<p>
        Việt Nam coi khoa học công nghệ là quốc sách hàng đầu, động lực then chốt cho phát triển công nghiệp hiện đại, tăng trưởng cao, bền vững, Chủ tịch nước khẳng định.
        <br/><br/>
        Chiều 16/9, Chủ tịch nướcNguyễn Xuân Phúctiếp một số nhà khoa học đoạt giải Nobel dự Hội nghị khoa học quốc tế tại tỉnh Bình Định. Cùng tham dự có Bộ trưởng Khoa học và Công nghệ Huỳnh Thành Đạt; lãnh đạo bộ ngành và tỉnh Bình Định.
        <br/><br/>
        Chủ tịch nước đánh giá cao sự phối hợp tổ chức thành công hội thảoKhoa học, đạo đức và phát triển con ngườicủa Chương trìnhGặp gỡ Việt Namvới Liên minh Nghị viện thế giới (IPU), Bộ Khoa học và Công nghệ, tỉnh Bình Định, Viện nghiên cứu phát triển Pháp (IRD) tại Việt Nam. Đây là sự kiện đặc biệt nằm trong chuỗi hoạt động của Bộ Khoa học và Công nghệ, hưởng ứng năm Quốc tế khoa học cơ bản vì phát triển bền vững do Liên Hợp Quốc thông qua tháng 12/2021 và UNESCO công bố tháng 7/2022.
        <br/><br/>
        Lắng nghe phát biểu của GS Trần Thanh Vân và 4 giáo sư nước ngoài, Chủ tịch nước yêu cầu Bộ Khoa học và Công nghệ tiếp thu, để tham khảo cho việc xây dựng chính sách, điều hành.
        <br/><br/>
        <img src=${imgnew33} alt="">
        <br/><br/>
        Chủ tịch nước Nguyễn Xuân Phúc tiếp các nhà khoa học đoạt giải Nobel, chiều 16/9. Ảnh:Hoàng Phong
        <br/><br/>
        Lãnh đạo Nhà nước khẳng định Việt Nam chú trọng phát triển con người toàn diện, phát huy sức sáng tạo to lớn của người dân, bảo đảm an ninh xã hội, chăm lo sức khỏe, sự an toàn và môi trường sống, không để ai bị bỏ lại phía sau. Việt Nam cũng tập trung xây dựng hệ giá trị văn hóa và chuẩn mực con người văn hóa mới trong cách mạng công nghiệp 4.0.
        <br/><br/>
        "Chúng tôi luôn ý thức về trách nhiệm đạo đức trong mỗi quyết sách, phải thực sự vì phát triển bền vững, vì lợi ích của người dân khi ứng dụng khoa học công nghệ hay phân bổ các nguồn lực, tài nguyên; không vì tăng trưởng cao mà hạ thấp lợi ích người dân, phá hủy môi trường", Chủ tịch nước chia sẻ.
        <br/><br/>
        Việt Nam phấn đấu năm 2045 là nước phát triển và luôn nỗ lực, vững tin vào con đường này. Chủ tịch nước bày tỏ mong muốn nhận được sự hỗ trợ, hợp tác của cộng đồng quốc tế, đặc biệt là các nhà khoa học.
        <br/><br/>
        GS Michel Spiro, Chủ tịch Hội đồng Năm quốc tế khoa học cơ bản cho phát triển bền vững, Chủ tịch Liên Hiệp các Hội Vật lý quốc tế, nhấn mạnh khoa học cơ bản là "những hạt giống, mầm mống phát triển cho tương lai không chỉ 10-20 mà 50 năm nữa".
        <br/><br/>
        Việc giáo dục không chỉ gói gọn trong khoa học công nghệ mà còn trong các lĩnh vực, ngành nghề khác hướng tới phát triển bền vững nói chung. Phát triển bền vững là quá trình nhiều thách thức, mục tiêu để xây dựng thế giới tốt đẹp hơn, cải thiện chất lượng cuộc sống, đạo đức cho người dân... GS Michel Spiro cho rằng Việt Nam đang đứng trước bước ngoặt quan trọng, có thể trở thành hình mẫu trong phát triển bền vững.
        <br/><br/>
        <img src=${imgnew34} alt="">
        <br/><br/>
        Chủ tịch nước Nguyễn Xuân Phúc, Bộ trưởng Khoa học và Công nghệ Huỳnh Thành Đạt chụp ảnh cùng các nhà khoa học, chiều 16/9. Ảnh:Hoàng Phong
        <br/><br/>
        GS Munasinghe Mohan, nguyên Phó chủ tịch Ủy ban Liên chính phủ (Tổ chức được trao giải Nobel Hòa bình 2007) đến từ Srilanka cho biết ông là người bạn lâu năm và rất ngưỡng mộ Việt Nam. "Việt Nam là điểm đến ưa thích của tôi và vợ. Chúng tôi đến cách đây 20 năm, cảm phục sự đón tiếp ấm áp, hiếu khách cũng như nể phục bản lĩnh kiên cường của người Việt Nam", ông chia sẻ.
        <br/><br/>
        Ông đánh giá Việt Nam có đầy đủ điều kiện tài nguyên, môi trường, con người để trở thành hình mẫu, tiên phong trong phát triển bền vững. "Tôi tin thế kỷ 21 thuộc về châu Á, khi trọng tâm kinh tế thế giới cũng đang chuyển dịch dần về khu vực châu Á", ông nói và khẳng định các nhà khoa học sẵn sàng đồng hành với lãnh đạo Việt Nam hướng tới tăng trưởng xanh, bền vững.
        <br/><br/>
        Nguồn: vnexpress.net
        </p>`,
        categoryID: 2
    },
    {
        id: 14,
        title: `Sau lãnh đạo Sở, đến lượt Phó Giám đốc Trung tâm y tế huyện xin nghỉ việc`,
        path: "sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec",
        title_content: `Ngày 30/10, bác sĩ Hà Ngọc Hải, Phó Giám đốc phụ trách Trung tâm Y tế (TTYT) huyện Đak Pơ cho biết, ông đã có đơn gửi Sở Y tế tỉnh Gia Lai xin nghỉ việc. Bác sĩ Hải sinh năm 1963, còn thời gian công tác đến hết năm 2024. Lý do nêu trong đơn của bác sĩ Hải là áp lực công việc và sức khỏe không đảm bảo.`,
        img: require("../images/news/events/1667117255-216fe46ac86f49235d554097958c707e-width645height428.jpg"),
        description: `<p>
        Ngày 30/10, bác sĩ Hà Ngọc Hải, Phó Giám đốc phụ trách Trung tâm Y tế (TTYT) huyện Đak Pơ cho biết, ông đã có đơn gửi Sở Y tế tỉnh Gia Lai xin nghỉ việc. Bác sĩ Hải sinh năm 1963, còn thời gian công tác đến hết năm 2024. Lý do nêu trong đơn của bác sĩ Hải là áp lực công việc và sức khỏe không đảm bảo.
        <br/><br/>
        <img src = ${imgnew35} alt="">
        <br/><br/>
        Phó Giám đốc phụ trách Trung tâm Y tế huyện Đak Pơ xin nghỉ việc
        <br/><br/>
        Trước đó trong 5 năm liền, bác sĩ Hà Ngọc Hải được xếp loại hoàn thành xuất sắc nhiệm vụ.
        <br/><br/>
        Năm 2013, khi đang là Phó Giám đốc TTYT huyện Đức Cơ, bác sĩ Hải được điều động về đảm nhận công tác tại huyện Đak Pơ. Đến năm 2020, ông được Sở Y tế phân công giữ chức Phó Giám đốc phụ trách TTYT huyện Đak Pơ.
        <br/><br/>
        Tại Gia Lai, trước đó vài ngày, ông Nguyễn Đình Tuấn (SN 1963), Phó Giám đốc phụ trách Sở Y tế Gia Lai cũng có đơn gửi cấp thẩm quyền xin nghỉ hưu dù còn 2 năm công tác.
        <br/><br/>
        Lý do xin nghỉ vì bác sĩ Tuấn sức khỏe không còn đảm bảo; muốn dừng công tác để chăm sóc mẹ già.
        <br/><br/>
        Nguồn: <a href="https://tienphong.vn/sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec-post1482286.tpo" style="color :black;text-decoration: none;" target="_blank"> https://tienphong.vn/sau-lanh-dao-so-den-luot-pho-giam-doc-trung-tam-y-te-huyen-xin-nghi-viec-post1482286.tpo</a>
        </p>`,
        categoryID: 2
    },
    {
        id: 15,
        title: `Quốc hội phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế, Bộ GTVT`,
        path: "quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt",
        title_content: `Ngoài phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế và Bộ trưởng Bộ Giao thông vận tải, Quốc hội sẽ bầu Tổng Kiểm toán Nhà nước.`,
        img: require("../images/news/events/1666318039-lan-16663142184791198203492-width684height484.jpeg"),
        description: `    <p>
        <span style="font-size : 2rem">Ngoài phê chuẩn bổ nhiệm Bộ trưởng Bộ Y tế và Bộ trưởng Bộ Giao thông vận tải, Quốc hội sẽ bầu Tổng Kiểm toán Nhà nước.</span>
        <br/><br/>Ngày 21-10, tiếp tục chương trình kỳ họp thứ 4 Quốc hội khoá XV, Quốc hội sẽ tiến hành những bước cuối cùng trong quy trình miễn nhiệm Tổng Kiểm toán nhà nước (KTNN) nhiệm kỳ 2021 - 2026 đối với ông Trần Sỹ Thanh; phê chuẩn đề nghị miễn nhiệm Bộ trưởng Bộ Giao thông vận tải (GTVT) nhiệm kỳ 2021 - 2026 đối với ông Nguyễn Văn Thể.
        <br/><br/>
        Quốc hội sẽ bỏ phiếu kín và biểu quyết thông qua Nghị quyết miễn nhiệm Tổng KTNN nhiệm kỳ 2021-2026; biểu quyết thông qua Nghị quyết phê chuẩn việc miễn nhiệm Bộ trưởng Bộ GTVT nhiệm kỳ 2021-2026.
        <br/><br/>
        Tại cuộc họp báo thông tin về chương trình kỳ họp thứ 4, Tổng Thư ký, Chủ nhiệm Văn phòng Quốc hội Bùi Văn Cường cho biết việc trình miễn nhiệm Bộ trưởng Bộ GTVT là theo nguyện vọng cá nhân và theo sự phân công, sắp xếp, bố trí của cơ quan có thẩm quyền về công tác nhân sự.
        <br/><br/>
        <img src=${imgnew36} alt="">
        <br/><br/>
        Bà Đào Hồng Lan hiện giữ chức Quyền Bộ trưởng Bộ Y tế
        <br/><br/>
        Cũng liên quan đến công tác nhân sự, trong ngày 21-10, Ủy ban Thường vụ Quốc hội trình Quốc hội về dự kiến nhân sự để Quốc hội bầu Tổng KTNN nhiệm kỳ 2021 - 2026. Thủ tướng Chính phủ trình Quốc hội về việc đề nghị phê chuẩn bổ nhiệm nhân sự Bộ trưởng Bộ Y tế và Bộ trưởng Bộ GTVT nhiệm kỳ 2021 - 2026. Sau đó, các đại biểu Quốc hội sẽ thảo luận tại đoàn về nội dung này.
        <br/><br/>
        Trong chiều 21-10, Quốc hội họp riêng về công tác nhân sự, trong đó sẽ báo cáo kết quả thảo luận tại đoàn. Sau đó, Quốc hội bầu Tổng KTNN, phê chuẩn bổ nhiệm nhân sự Bộ trưởng Bộ Y tế và Bộ trưởng Bộ GTVT nhiệm kỳ 2021 - 2026 bằng hình thức bỏ phiếu kín. Từ kết quả kiểm phiếu, Quốc hội sẽ biểu quyết thông qua các Nghị quyết về nhân sự.
        <br/><br/>
        <img src=${imgnew37} alt="">
        <br/><br/>
        Ông Ngô Văn Tuấn hiện là Phó Tổng KTNN phụ trách KTNN
        <br/><br/>
        Đối với Bộ Y tế, hiện bà Đào Hồng Lan đang giữ chức Quyền Bộ trưởng. Cụ thể vào ngày 15-7, Thủ tướng Phạm Minh Chính đã trao quyết định của Bộ Chính trị điều động bà Đào Hồng Lan thôi giữ chức Bí thư Tỉnh ủy Bắc Ninh để giữ chức Bí thư Ban cán sự đảng Bộ Y tế; giao quyền Bộ trưởng Y tế đối với bà Đào Hồng Lan, Ủy viên Trung ương Đảng, Bí thư Ban cán sự đảng Bộ Y tế.
        <br/><br/>
        Về phía KTNN, cuối tháng 7-2022, Bộ Chính trị quyết định điều động, phân công ông Ngô Văn Tuấn, Ủy viên Trung ương Đảng, thôi tham gia Ban chấp hành, Ban Thường vụ và thôi giữ chức Bí thư Tỉnh ủy Hòa Bình nhiệm kỳ 2020 - 2025, để chỉ định giữ chức Bí thư Ban cán sự Đảng, Phó tổng KTNN phụ trách KTNN.
        <br/><br/>
        Nguồn: <a hred="https://nld.com.vn/thoi-su/quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt-20221020152215496.htm" alt="" style="color:black;text-decoration: none" target="_blank">https://nld.com.vn/thoi-su/quoc-hoi-phe-chuan-bo-nhiem-bo-truong-bo-y-te-bo-gtvt-20221020152215496.htm</a>
        </p>`,
        categoryID: 2
    },
    {
        id: 16,
        title: `Tổng bí thư đến Bắc Kinh, bắt đầu thăm chính thức Trung Quốc`,
        path: "tong-bi-thu-den-bac-kinh-bat-dau-tham-chinh-thuc-trung-quoc",
        title_content: `Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh vào lúc 13h20 ngày 30/10 (giờ địa phương), bắt đầu chuyến thăm chính thức Trung Quốc.`,
        img: require("../images/news/events/vna_potal_tong_bi_thu_nguyen_phu_trong_den_bac_kinh_bat_dau_chuyen_tham_chinh_thuc_trung_quoc_6411863_2.jpg"),
        description: `    <p>
        Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh vào lúc 13h20 ngày 30/10 (giờ địa phương), bắt đầu chuyến thăm chính thức Trung Quốc.
        <br/><br/>
        Ông Lưu Kiến Siêu (bên phải), Ủy viên Trung ương Đảng, Trưởng Ban Liên lạc Đối ngoại Trung ương Đảng Cộng sản Trung Quốc đón Tổng bí thư Nguyễn Phú Trọng tại sân bay Quốc tế Bắc Kinh. Ảnh: TTXVN.
        <br/><br/>
        <img src=${imgnew38} alt"">
        <br/><br/>
        Theo đặc phái viênTTXVN, vào lúc 13h20 (giờ địa phương - tức 12h20 giờ Hà Nội) ngày 30/10, Tổng bí thư Ban Chấp hành Trung ương Đảng Cộng sản Việt Nam Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam đã đến thủ đô Bắc Kinh, bắt đầu chuyến thăm chính thức nước Cộng hòa Nhân dân Trung Hoa theo lời mời của Tổng bí thư Ban Chấp hành Trung ương Đảng Cộng sản Trung Quốc, Chủ tịch nước Cộng hòa Nhân dân Trung Hoa Tập Cận Bình.
        <br/><br/>
        Đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam tại Sân bay Bắc Kinh có ông Lưu Kiến Siêu, Trưởng Ban Liên lạc Đối ngoại Trung ương Đảng Cộng sản Trung Quốc; Đại sứ Việt Nam tại Trung Quốc Phạm Sao Mai, cùng một số cán bộ Đại sứ quán tại Trung Quốc.
        <br/><br/>
        Nghi thức tặng hoa chào mừng tại lễ đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Đảng Cộng sản Việt Nam ở sân bay quốc tế Bắc Kinh. Ảnh: TTXVN.
        <br/><br/>
        <img src=${imgnew39} alt"">
        <br/><br/>
        Sân bay Bắc Kinh rực rỡ quốc kỳ Việt Nam và quốc kỳ Trung Quốc. Hai hàng tiêu binh dọc hai bên thảm đỏ. Đồng chí Lưu Kiến Siêu cùng các quan chức Trung Quốc nồng nhiệt chào đón Tổng bí thư Nguyễn Phú Trọng và Đoàn đại biểu cấp cao Việt Nam.
        <br/><br/>
        Chuyến thăm chính thức Cộng hòa Nhân dân Trung Hoa của Tổng bí thư Nguyễn Phú Trọng nhằm đề cao đường lối đối ngoại, trong đó khẳng định chủ trương nhất quán của Việt Nam coi trọng quan hệ với Trung Quốc là ưu tiên hàng đầu trong chính sách đối ngoại, mong muốn phát triển lâu dài, ổn định, ngày càng hiệu quả, thực chất theo tinh thần nhận thức chung của lãnh đạo cấp cao hai đảng, hai nước; đồng thời làm rõ những quan tâm, lập trường đúng đắn, các lợi ích chính đáng của Việt Nam.
        <br/><br/>
        Cùng với đó, chuyến thăm cũng thể hiện sự ủng hộ đối với những tư tưởng và đường lối phát triển của Đại hội XX Đảng Cộng sản Trung Quốc có lợi cho hòa bình, hợp tác, phát triển; vị trí “hạt nhân lãnh đạo” của Tổng bí thư Tập Cận Bình được Đảng Cộng sản Trung Quốc xác lập; đồng thời thúc đẩy chính sách của Trung Quốc về hữu nghị, hợp tác với Việt Nam; ủng hộ Việt Nam phát triển, có vị thế quốc tế ngày càng cao, thực hiện thắng lợi Nghị quyết Đại hội XIII của Đảng..
        <br/><br/>
        Nguồn : zingnews.vn
        </p>`,
        categoryID: 2
    },

]