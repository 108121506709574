import { useNavigate } from "react-router-dom";
import { useState } from 'react'
import { ImFacebook } from "react-icons/im";
import { TiSocialTwitter } from "react-icons/ti";
import { FaInternetExplorer } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai"
import { BsGoogle, BsMicrosoft } from "react-icons/bs"
import { GrOracle, GrVmware } from "react-icons/gr"
import { SiFortinet, SiNetapp } from "react-icons/si"
import { FiPhone } from "react-icons/fi";
import { MdHome, MdFax } from "react-icons/md";
import { listMenu } from "../../localData/menuft";
const imgQR = require("../../images/QR/qrfooter.png");
const imglogo = require("../../images/Logo/Logo HSV_01.png");



function Footer() {
    const [activeMenu, setActiveMenu] = useState("Trang chủ");
    const navigate = useNavigate();

    const handleActiveMenu = (menuItem, slug) => {
        setActiveMenu(menuItem)
        if (slug !== undefined) {
            navigate(`/${slug}`)
        }
    }

    return (
        <footer className="footer">
            <div className='layout_container'>
                <span></span>
                <div className='footer_top'>
                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-lg-4">
                            <div className="footer_contract" style={{display:"flex",alignItems:"center"}}>
                                <div>
                                    <img src={imglogo} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-4">
                            <div className="footer_contract">
                                <div>
                                    <p>Trụ sở Hồ Chí Minh</p>
                                    <p><MdHome className="icon-contact" />64 Đường số 2, KDC Him Lam, Phường Tân Hưng, Quận 7, TP. Hồ Chí Minh</p>
                                    <p><FiPhone className="icon-contact" />Hotline: (84)907.632.112</p>
                                    <p><MdFax className="icon-contact" />Fax: (84-28) 38371002</p>
                                    <span></span>
                                </div>
                                <div>
                                    <p>Văn phòng đại diện</p>
                                    <p><MdHome className="icon-contact" />232/7 Võ Văn Kiệt, Phường Cầu Ông Lãnh, Quận 1, TP. Hồ Chí Minh</p>
                                    <p><FiPhone className="icon-contact" />Hotline: (84) 917.632.112</p>
                                    <p><MdFax className="icon-contact" />Fax: (84-28) 39300614</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-4" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            <div className='footer_icons'>
                                <div className='footer_icons-left'>
                                    <p onClick={() => window.open("https://www.facebook.com/profile.php?id=100063723542675")}><ImFacebook /></p>
                                    <p onClick={() => window.open("https://www.hsv.com.vn")}><TiSocialTwitter /></p>
                                    <p onClick={() => window.open("https://www.hsv.com.vn")}><FaInternetExplorer /></p>
                                    <p onClick={() => window.open("https://www.youtube.com/watch?v=kYRAM1ZohXM")} ><AiFillYoutube /></p>
                                    <p onClick={() => window.open("https://www.hsv.com.vn")}><BsGoogle /></p>
                                </div>
                            </div>
                            <div className='footer_qr'>
                                <img src={imgQR} alt="" />
                            </div>
                            <div className='footer_icons-Partner'>
                                <p onClick={() => window.open("https://www.microsoft.com/vi-vn")}><BsMicrosoft /></p>
                                <p onClick={() => window.open("https://www.oracle.com/")}><GrOracle /></p>
                                <p onClick={() => window.open("https://www.fortinet.com/")}><SiFortinet /></p>
                                <p onClick={() => window.open("https://www.vmware.com/products/workstation-pro/workstation-pro-evaluation.html")}><GrVmware /></p>
                                <p onClick={() => window.open("https://www.netapp.com/")}><SiNetapp /></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer_bottom" style={{justifyContent:"center"}}>
                <p>Copyright © 2016  Healthcare Solution Viet Nam.</p>
                <ul className="footer_bottom-menu">
                    {
                        listMenu.map(value => (
                            <li key={value.id} className={activeMenu === value.text} onClick={() => handleActiveMenu(value.text, value.slug)}>{value.text} </li>
                        ))
                    }
                </ul>
                {/* <p>HSV [v.0.0.1]</p> */}
            </div>
        </footer>


    )
}

export default Footer