import React from 'react'
import { AiFillPlayCircle } from "react-icons/ai";
import Buttun2 from '../../components/button_2/button';
import img from '../../images/banner/esm.jpg';


function EsmSection() {
  return (
    <div className='home_clientsWords-content sectionEsm'>
      <div className="">
        <img src={img} alt="img section3.1" onClick={() => window.open("http://esm.vn/")} style={{ cursor: "pointer" }} />
        <div className='sectionEsm-content'>
          <h3 className='sectionEsm-content-title' style={{ textAlign: "center" }}>ECOSYSTEM SMART MEDIC</h3>
          <p style={{ marginTop: "10px", textAlign: "center", fontWeight: "normal" }}>
            Esm là hệ sinh thái thông minh cho ngành y tế có vốn đầu tư nước ngoài tự tin hoạt động trong lĩnh vực Chăm sóc sức khỏe & Dược phẩm, giải pháp công nghệ.
          </p>
          <p>
            <h3>NHIỆM VỤ CỦA ESM</h3>
            “Sứ mệnh của chúng tôi là nâng cao sức khỏe và hạnh phúc bằng cách cung cấp dịch vụ chăm sóc sức khỏe tổng thể tốt nhất. ”
          </p>
          <a style={{ marginTop: "20px" }}
            href="/ESM Hệ Sinh Thái Chăm Sóc Sức Khỏe Từ Xa Toàn Diện V2.pptx"
            download="ESM Hệ Sinh Thái Chăm Sóc Sức Khỏe Từ Xa Toàn Diện V2.pptx">
            <Buttun2 text="Xem Chi Tiết" Icon={AiFillPlayCircle} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default EsmSection