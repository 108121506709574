import React, { useEffect } from 'react'
// import Slider from '../../components/slider/slider'

import Banner from '../../components/slider/slider';
// import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { newsMenu } from '../../localData/newsMenu'

// import {
//   Autoplay,
//   Pagination,
//   Navigation,
//   // EffectCoverflow
// } from 'swiper'


import cutLIcon from "../../images/icons/adoption.png"
import saveMoneyIcon from "../../images/icons/growth-graph.png"
import friendly from "../../images/icons/trophy.png"
import techIcon from "../../images/icons/settings.png"
import humanIcon from "../../images/icons/group.png";
import technologyIcon from "../../images/icons/project-management1.png"

import Section4 from './section4';

import AOS from 'aos';
import 'aos/dist/aos.css';
import ListProjectSolution from './listProjectSolution';
import 'swiper/css';
import { useNavigate } from 'react-router-dom';

// import { useParams } from 'react-router-dom';
import HomeTitle from './homeTitle';
import TypicalProject from './typicalProject';
import ListTypicalInProgress from './ListTypicalInProgress';
import LogoSlider from './LogoSlider';
import EsmSection from './EsmSection';

function Home() {
  const navigate = useNavigate();

  const selectNews = newsMenu.slice(0, 4);
  // const [count, setCount] = useState(1);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     if (count < 00) {
  //       setCount(count + 1);
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 20);
  //   return () => clearInterval(intervalId);
  // }, [count]);

  // useEffect(() => {
  //   // 👇️ scroll to top on page load
  //   window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  // }, []);


  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <div className='home' >
      <Banner />
      <section className='section-fullscreen' style={{ perspective: "20px" }}>
        <div className='section-img' style={{ transformStyle: "preserve-3d" }}>
          <div className="layout_container section1">
            <div className='section1-left'></div>
            <div className='section1-right'>
              <div style={{ transform: "translateZ(-10px) scale(1.)" }}
                data-aos="fade-down"
                data-aos-once="true"
                data-aos-delay="200"
              >
                <p>Giới Thiệu</p>
                <h4>HEALTHCARE SOLUTION VIETNAM</h4>
                <p>Đặt mục tiêu - nhiệm vụ xây dựng và phát triển một nền tảng số với nhiều công cụ phần mềm ứng dụng tiên tiến,
                  giúp các doanh nghiệp nâng cao hiệu quả quản trị trải nghiệm khách hàng,
                  quản trị chăm sóc, tìm kiếm, gắn kết khách hàng lên một tầm cao mới.</p>
              </div>
              <div className='section1-model'>
                <div data-aos="fade-right"
                  data-aos-once="true"
                  data-aos-delay="300">
                  <img src={technologyIcon} alt="technology icon" />
                  <p>Công nghệ nền tảng tiên tiến</p>
                  <p>Với lợi thế là đối tác chiến lược của các hãng công nghệ thông tin hàng đầu thế giới tại Việt Nam như HP, Cisco, Microsoft, IBM, APC, Polycom, Oracle…., HSV đem đến cho khách hàng sự thỏa mãn về giải pháp công nghệ và chất lượng.</p>
                </div>
                <div data-aos="fade-left"
                  data-aos-once="true"
                  data-aos-delay="300">
                  <img src={humanIcon} alt="human icon" />
                  <p>Nhân lực tạo tên sức mạnh</p>
                  <p>Đội ngũ kỹ sư giàu kinh nghiệm, được đào tạo các trường uy tín, và được đào tạo chứng nhận bởi các tổ chức có uy tin quốc tế như Microsoft, Cisco, HP, DellEMC…</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-fullscreen'>
        <div className="layout_container section2 p-bt-100" style={{ padding: "0 0 50px 0" }}>
          <div>
            <div className='section2-content row'>
              <div className=' col-sm-6 col-md-3 col-lg-3' data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="200">
                <div className='section2-content-item'>
                  <div>
                    <img src={cutLIcon} alt="penci icon" />
                  </div>
                  <p>15 Năm</p >
                  <p>Hình thành & phát triển.</p>
                </div>
              </div>
              <div className=' col-sm-6 col-md-3 col-lg-3' data-aos="fade-right"
                data-aos-once="true"
                data-aos-delay="200">
                <div className='section2-content-item'>
                  <div>
                    <img src={saveMoneyIcon} alt="penci icon" />
                  </div>
                  <p>1000 Tỷ</p >
                  <p>Kế hoạch mục tiêu 2023 - 2027 - tổng doanh thu.</p>
                </div>
              </div>
              <div className='col-sm-6 col-md-3 col-lg-3' data-aos="fade-left"
                data-aos-once="true"
                data-aos-delay="400">
                <div className='section2-content-item '>
                  <div>
                    <img src={techIcon} alt="cut icon" />
                  </div>
                  <p>Top 12</p >
                  <p>Đơn vị cung cấp dịch vụ tích hợp hệ thống hàng đầu Việt Nam.</p>
                </div>
              </div>
              <div className=' col-sm-6 col-md-3 col-lg-3' data-aos="fade-left"
                data-aos-once="true"
                data-aos-delay="200">
                <div className='section2-content-item'>
                  <div>
                    <img src={friendly} alt="frinedly icon" />
                  </div>
                  <p>3 Năm</p >
                  <p>Giải thưởng cúp vàng do Hiệp hội CN TP HCM.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section-fullscreen'>
        <div className='layout_container home_ourservices'>
          <HomeTitle text="Chúng tôi có" title="Sản Phẩm và Giải Pháp" />
          <ListProjectSolution />
        </div>
      </section>

      <section className='section-fullscreen'>
        <div className='home_ourservices'>
          <HomeTitle text="Dịch Vụ" title="Sản phẩm của công ty" />
          <LogoSlider />
        </div>
      </section>

      <section className='section-fullscreen'>
        <div className='home_ourservices layout_container'>
          <HomeTitle text="ESM" title="Hệ sinh thái ESM" />
          <EsmSection/>
        </div>
      </section>

      <section className='section-fullscreen'>
        <div className='layout_container home_ourservices'>
          <HomeTitle text="Nổi Bật" title="Dự Án Tiêu Biểu" />
          <TypicalProject />
        </div>
      </section>

      <section className='section-fullscreen' style={{marginTop:"30px"}}>
        <div className='layout_container home_ourservices' style={{ padding: "unset" }}>
          <HomeTitle text="Dự Án" title="Đang Được Triển Khai" />
          <ListTypicalInProgress />
        </div>
      </section>

      <Section4 />


      {/* <section className='section-fullscreen'>
        <div className="layout_container home_clientsWords">
          <HomeTitle text="Bộ Máy Công Ty" title="Ban Lãnh Đạo" />
          <div className='home_clientsWords-content'>
            <ul className='row'>
              {leaders.map((value, index) => {
                if (value.category === 1) {
                  return (
                    <div className='col-sm-6 col-md-3 col-lg-4'
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`/leader/${value.category}/${value.slug}`)}>
                      <li key={index}>
                        <div>
                          <img src={value.img} alt="" />
                        </div>
                        <p style={{ fontWeight: "600", color: "#333", marginBottom: "10px" }}>{value.gender === 1 ? "Ông" : "Bà"}: {value.name}</p>
                        <ul className='category_leader'>
                          {
                            value.position.map((positionValue, positionIndex) => (
                              <li key={positionIndex}>
                                <span>{positionValue}</span>
                              </li>
                            ))
                          }
                          <p className='leader-summary'>{value.summary}</p>
                        </ul>
                      </li>
                    </div>
                  );
                }
                return null; 
              })}
            </ul>
          </div>
        </div>
      </section> */}

      <section className='section-fullscreen home_blog'>
        <div className='layout_container'>
          <HomeTitle title="Tin Tức" text="Có thể bạn chưa biết?" />
          <div className='home_blog-content'>
            <ul className='row'>
              {selectNews.map((value, index) => {
                return (
                  <div key={index} className='col-sm-6 col-md-3 col-lg-3' style={{ marginBottom: "15px" }}>
                    <li key={index} onClick={() => navigate(`/tin-tuc/${value.path}`)}>
                      <div className='home_blog-content-img'>
                        <img src={value.img} alt="" />
                      </div>
                      <div className='home_blog-content-time'>
                        {/* <span>25 APRIL 2023</span> */}
                      </div>
                      <p className='home_blog-content-title'>
                        {value.title}
                      </p>
                      <p className='home_blog-content-description'>
                        {value.title_content}
                      </p>
                    </li>
                  </div>);
              })}
            </ul>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Home
